import Link from "./link";
import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import GraceLogo from "./grace-logo";

const HeroImage = ({image}) => {
return(
  <div className="hero--image p40 mt80 max-1400 ma pointer-none">
    <div className="text-center mt80 m-mt20 pointer-all m-show">
    <GraceLogo style={{height:'40px'}}/>
      <div className="max-650 ma m-p10-30">
        <h3 className="m0 mt20 m-m0">A Brisbane based creative specialising in 
        <Link className='link ul-link inverse l-no-p'  to='/folio/?filter=editorial'> editorial<sup>1</sup></Link>, <br className="m-hide"/>
        <Link className='link ul-link inverse l-no-p' to='/folio/?filter=campaign'> campaign<sup>2</sup></Link>, 
        <Link className='link ul-link inverse l-no-p' to='/folio/?filter=portrait'> portrait<sup>3</sup></Link> and 
        <Link className='link ul-link inverse l-no-p' to='/folio/?filter=wedding'> wedding<sup>4</sup></Link> photography.</h3>
      </div> 
    </div>
    <div className="ratio-2-1 m-ratio-2-1 pos-rel hero--image-wrapper">
      <div className="title-area text-center mt80 m-mt20 pointer-all m-hide">
      <GraceLogo/>
        <div className="max-650 ma m-p20">
          <h3 className="m0 mt20 m-m0">A Brisbane based creative specialising in 
          <Link className='link ul-link inverse l-no-p'  to='/folio/?filter=editorial'> editorial<sup>1</sup></Link>, <br className="m-hide"/>
          <Link className='link ul-link inverse l-no-p' to='/folio/?filter=campaign'> campaign<sup>2</sup></Link>,  
          <Link className='link ul-link inverse l-no-p' to='/folio/?filter=portrait'> portrait<sup>3</sup></Link> and 
          <Link className='link ul-link inverse l-no-p' to='/folio/?filter=wedding'> wedding<sup>4</sup></Link> photography.</h3>
        </div> 
      </div>
      <GatsbyImage className='bg-image' image={image.gatsbyImageData} alt='Hero Image' /> 
    </div>
  </div> )
}
export default HeroImage
