import { useState, useCallback } from "react"

export default function useImagesLoaded(toLoad) {
    // eslint-disable-next-line
    const [_,setLoaded] = useState(0)
    const [isLoaded,setIsLoaded] = useState(false)

    const onLoad = useCallback(() => {
        setLoaded((load) =>  {
            if(load + 1 === toLoad) setIsLoaded(true);
            return load + 1 
        })
    },[toLoad, setLoaded])
  
    return {
        onLoad,
        isLoaded
    }
}