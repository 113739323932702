import * as React from "react"
import Spacer from "../components/spacer"

const HeroImage = ({text}) => (
  <div className="text--banner p40 max-1400 ma">
    <Spacer className='m-small' />
    <div className="h3 max-450 m-max-300 ma text-center fade--in" data-sal>
    {text}
    </div>
    <Spacer className='m-small' />
  </div> 
) 
export default HeroImage
