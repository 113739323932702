import React, { useCallback } from 'react'
import { gsap } from "gsap"
import { GatsbyImage } from 'gatsby-plugin-image' 
import { useEffect } from 'react'
import useImagesLoaded from '../utils/useImagesLoaded'

export default function Loader({ home, internal, images }) {
    const {onLoad,isLoaded} = useImagesLoaded(images.length)
    const assets = images.map((image, index) => {
        return (
            <GatsbyImage className={"bg-image" + (index+1===images.length ? ' ' : ' animate')}  style={{zIndex : images.length - index}} image={image.gatsbyImageData} onLoad={onLoad} alt="Gallery Image" key={index} /> 
        ) 
    })

    const animationStart = useCallback(()  => {
        var tl = gsap.timeline({ paused: true});
        tl.to(".loader .intro--logo", {opacity:0, duration: .5, delay:.5}); 
        tl.to(".animation-wrapper", {opacity:1, duration: .5, delay:.5, ease:"power2.in"});
        tl.staggerTo(".animation-wrapper .bg-image.animate", .5, {display:'none'}, 0.2);
        tl.to('.animation-wrapper', {onComplete:introAnimation})
        tl.play(); 
    },[])


    useEffect(() => {
        if(isLoaded && !internal) {
            var images = document.querySelector('.loader .image--wrapper');
            var top = (window.innerHeight - images.offsetHeight)/2;
            images.style.top = top+'px';
            animationStart();
        }
    },[isLoaded,animationStart,internal])

    function introAnimation() {
        window.scrollTo(0, 0);
        var padding  = '80px';
        var top = 120;
        var duration = 1.5;
        if(window.innerWidth < 768) {
            padding = '40px';
            duration = 1;
            top = document.querySelector('.hero--image-wrapper').offsetTop;
        }
        var tl = gsap.timeline({ paused: true});
        tl.to(".loader .image--wrapper", {width:'calc(100% - '+padding+')', top:top, duration: duration, delay:0.5, ease:"power3.inOut"}); 
        tl.to('.loader', {opacity:0, display:'none', delay:.1, duration:.75}) 
        tl.play();
    }

    if(!internal) {
        return (
        <div id='loader'  className='loader'>
            <div className="intro--logo"><div className="inner"></div></div> 
            <div className="animation-wrapper max-1600">
                <div className="image--wrapper pos-rel">
                    <div className="ratio-2-1 m-ratio-2-1">
                        {assets}
                    </div>
                </div>
            </div>
        </div>
        )
    } 
    return null  
}
