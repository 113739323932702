import * as React from "react"
import Link from "../components/link"
import { GatsbyImage } from "gatsby-plugin-image"

const ProjectFeed = ({feed}) => {

  function changeBackgroundcolor(color) {
    if(window.innerWidth > 768){
      document.body.style.backgroundColor = color ;
    }
  }

  function resetBackgroundColor() {
    document.body.style.backgroundColor = 'inherit'
  }

  const projects = feed.map((project, index) => {
    const color = project.color?.hex
    return (
      <Link to={'/portfolio/'+project.slug} className="hover--zoom link" key={index} onMouseLeave={resetBackgroundColor} onMouseEnter={() => changeBackgroundcolor(color)}> 
        <div className="project pointer-none">
          <div className="overflow-hidden"> 
            <div className="zoom--in" data-sal>
              <GatsbyImage className="hover-item" image={project.heroImage.gatsbyImageData} alt={project.title} /> 
            </div>
          </div>
          <p className="m0 mb20 mt10 uppercase spacing m-xs">{project.title}</p>
        </div>
      </Link>
    )
  })
  return (
    <div className="project--feed p40">
      <div className="grid masonry max-1600 ma">
        {projects}
        <div className="more--projects text-center flex flex-column justify-center align-center fade--in" data-sal>
          <p className="m0 mb40 h3">There’s plenty more</p>
          <Link className="link ul-link uppercase med spacing" to="/folio">View All</Link>
        </div>
      </div>
      <div className="more--projects m-show text-center flex flex-column justify-center align-center fade--in" data-sal>
          <p className="m0 mb40 h3 m-xl">There’s plenty more</p>
          <Link className="link ul-link uppercase med spacing m-xs" to="/folio">View All</Link>
        </div>
    </div>  
  ) 
}
export default ProjectFeed
