import Link from "./link"
import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"
import Spacer from "../components/spacer"

const CallToAction = ({image, title, text, button, link}) => (
  <>
    <Spacer className="m-hide" />
    <div className="call-to--action p40 grid grid-2 m-flex max-1600 ma m-mt40">
        <div className="flex flex-column justify-center  align-center fade--in" data-sal>
          <h3 className="text-center mt0 mb20 m-mt20 m-xl">{title}</h3> 
          <p className="text-center mb40 max-250 mt0">{text}</p>
          <Link to={link} className="link ul-link text-center uppercase med spacing m-xs">{button}</Link>
        </div>
        <div className="image-wrapper"> 
          <Link to={link} className="hover--zoom link"> 
            <div className="overflow-hidden">
              <div className="zoom--in" data-sal>
                <GatsbyImage className="hover-item" image={image.gatsbyImageData} alt={title}/>
              </div>
            </div>
          </Link>
        </div>
    </div> 
  </>
) 
export default CallToAction
