import * as React from "react"
import CTA from "../components/cta"
import Layout from "../components/layout"
import HeroImage from "../components/heroImage"
import TextBanner from "../components/textBanner"
import ProjectFeed from "../components/projectFeed"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import Loader from "../components/loader" 
class IndexPage extends React.Component {
	render() {
		var data = this.props.data.allDatoCmsHomepage.nodes[0]; 
		const loadingImages = data.loadingImages 
		const internal = this.props.location?.state?.internal

	  return (
		  <>
			<Loader internal={internal} images={loadingImages}/>   
		  <Layout page='homepage'>
		   	<SEO title="Grace Elizabeth | Photographer" /> 
		   	<HeroImage image={loadingImages[loadingImages.length-1]} />
		   	<TextBanner text={data.textContent} />
		  	<ProjectFeed feed={data.featuredProjects} />
		  	<CTA title={data.ctaTitle} text={data.ctaSubtitle} button={data.ctaButton} link={data.ctaLink}  image={data.ctaImage} />
		  </Layout>
		  </>
		)
	}
}

export default IndexPage

export const query = graphql`
query HomeQuery {
	allDatoCmsHomepage {
    nodes {
    	ctaTitle
      ctaLink
      ctaButton
      ctaSubtitle
      textContent
      ctaImage {
      	gatsbyImageData(placeholder:NONE)
      }
	  	loadingImages {
				gatsbyImageData(placeholder:NONE)
	  	}
	  	featuredProjects {
        title
	      color:projectColour {
	        hex
	      }
	      slug
	      position
	      heroImage {
	    		gatsbyImageData(placeholder:NONE)
	    	}
      }
    }
  }
}
`