import React from 'react'

export default function GraceLogo({...props}) {
  return (
<svg {...props} width="100%" height="74" viewBox="0 0 749 74" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_352_449)">
<path d="M70.4891 41.0469L70.4067 41.3855H39.8474V43.731H69.1375C66.7557 57.7712 54.5254 69.2511 40.3996 70.6551C23.6282 72.3069 7.02168 57.6638 4.8789 39.2712C4.30302 34.9308 4.60721 30.5185 5.77324 26.2987C6.93927 22.0788 8.94315 18.1382 11.6649 14.7128C14.3866 11.2875 17.7702 8.44781 21.613 6.3639C25.4558 4.27999 29.6787 2.99469 34.0289 2.58502C50.6602 0.7598 65.6102 14.1475 68.0497 33.1431L68.1156 33.6881H73.1594L73.0769 32.9944C72.0944 23.9683 67.8351 15.619 61.1093 9.5353C54.3834 3.45156 45.6605 0.0579039 36.6003 0C17.3812 0 0.997172 15.8241 0.0823701 35.2656C-0.166699 40.2648 0.608625 45.262 2.36055 49.9494C4.11247 54.6368 6.80386 58.915 10.2688 62.5201C13.6591 66.1378 17.7539 69.0202 22.2999 70.9891C26.846 72.958 31.7467 73.9715 36.6992 73.967C43.8015 73.9568 50.7306 71.7678 56.5547 67.6942C62.3788 63.6207 66.8187 57.8578 69.2776 51.1806V72.6538H74.2225V41.3855H70.5303L70.4891 41.0469Z" fill="#2E4FFF"/>
<path d="M121.026 51.9159L119.839 48.4223C117.482 41.584 112.924 37.0498 105.944 34.6465C118.026 33.0607 123.572 25.2808 123.572 18.1286C123.572 10.3487 116.493 1.97412 100.95 1.97412H82.9502V72.6623H87.4912V34.919H94.4388C108.449 35.5219 112.57 41.9639 114.985 48.827C117.399 55.6902 118.578 59.2993 121.026 66.7324L123.02 72.7284H128.468L128.146 71.9025C124.298 61.711 122.929 57.6476 121.026 51.9159ZM87.4912 4.4518H100.966C112.141 4.4518 118.545 9.44846 118.545 18.1616C118.545 27.1804 112.199 32.5735 101.568 32.5735H87.4912V4.4518Z" fill="#2E4FFF"/>
<path d="M158.03 2.00684L133.701 72.695H136.215L146.014 44.2347H170.738L181.238 72.695H186.059L160 2.00684H158.03ZM146.838 41.8892L157.89 9.58853L169.873 41.8892H146.838Z" fill="#2E4FFF"/>
<path d="M220.541 2.54392C237.173 0.718701 252.114 14.1065 254.554 33.102L254.628 33.6471H259.573L259.499 32.9533C258.54 23.9342 254.294 15.5869 247.573 9.51189C240.852 3.43685 232.131 0.0615749 223.08 0.0332031C203.861 0.0332031 187.477 15.8573 186.562 35.2988C186.313 40.298 187.088 45.2953 188.84 49.9827C190.592 54.6701 193.283 58.9482 196.748 62.5533C200.139 66.171 204.233 69.0535 208.779 71.0223C213.325 72.9912 218.226 74.0047 223.179 74.0002C240.271 74.0002 255.065 61.4962 257.595 44.9122L257.702 44.1937H255.576L255.469 44.6892C252.724 58.1843 240.444 69.3504 226.92 70.6553C210.132 72.241 193.526 57.5649 191.391 39.2714C190.816 34.9303 191.12 30.5174 192.287 26.2971C193.453 22.0767 195.458 18.1358 198.18 14.7103C200.903 11.2848 204.287 8.44527 208.131 6.36181C211.975 4.27836 216.199 2.99384 220.55 2.58521L220.541 2.54392Z" fill="#2E4FFF"/>
<path d="M271.795 34.9104H298.695V32.5649H271.795V4.35237H305.494V2.00684H267.254V72.695H306.689V70.3495H271.795V34.9104Z" fill="#2E4FFF"/>
<path d="M349.693 34.9104H376.593V32.5649H349.693V4.35237H383.392V2.00684H345.152V72.695H384.587V70.3495H349.693V34.9104Z" fill="#2E4FFF"/>
<path d="M396.892 2.00684H392.351V72.695H425.193V70.3495H396.892V2.00684Z" fill="#2E4FFF"/>
<path d="M437.498 2.00684H432.957V72.695H437.498V2.00684Z" fill="#2E4FFF"/>
<path d="M488.397 4.17068L490.012 2.00684H448.854V4.45148H483.015L446.168 70.6055L444.651 72.695H490.095V70.3495H451.632L488.397 4.17068Z" fill="#2E4FFF"/>
<path d="M521.907 2.00684H519.929L495.649 72.695H498.122L507.921 44.2347H532.645L543.145 72.695H547.966L522.022 2.41152L521.907 2.00684ZM508.72 41.8892L519.772 9.58853L531.755 41.8892H508.72Z" fill="#2E4FFF"/>
<path d="M580.767 33.5312C587.674 31.5408 593.698 26.4202 593.698 18.1613C593.698 10.3814 587.212 2.00684 572.971 2.00684H554.452V72.695H573.408C581.83 72.695 588.869 70.5642 593.772 66.5421C595.899 64.8728 597.621 62.7423 598.809 60.3106C599.996 57.8789 600.619 55.2092 600.629 52.5019C600.596 45.0276 595.313 35.951 580.767 33.5312ZM588.951 18.1696C589.032 20.0335 588.739 21.8946 588.091 23.6435C587.443 25.3924 586.452 26.9937 585.176 28.3528C582.457 31.1113 578.41 32.5731 573.473 32.5731H558.993V4.45147H572.872C583.009 4.45147 588.852 9.44814 588.951 18.1696ZM558.993 34.9186H573.119C586.956 34.9186 595.289 41.5258 595.371 52.535C595.444 54.7091 595.077 56.8755 594.292 58.9038C593.508 60.9321 592.322 62.7802 590.805 64.3369C586.915 68.2682 580.858 70.3495 573.292 70.3495H558.993V34.9186Z" fill="#2E4FFF"/>
<path d="M612.892 34.9104H639.792V32.5649H612.892V4.35237H646.592V2.00684H608.351V72.695H647.795V70.3495H612.892V34.9104Z" fill="#2E4FFF"/>
<path d="M653.556 4.45148H674.052V72.695H678.494V4.45148H698.999V2.00684H653.556V4.45148Z" fill="#2E4FFF"/>
<path d="M744.459 2.00684V32.5649H710.298V2.00684H705.757V72.695H710.298V34.9104H744.459V72.695H749V2.00684H744.459Z" fill="#2E4FFF"/>
</g>
<defs>
<clipPath id="clip0_352_449">
<rect width="749" height="74" fill="white"/>
</clipPath>
</defs>
</svg>

  )
}
